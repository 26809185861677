export const MenuItems = [
    {
        itemName: 'Home',
        url: '/',
        cName: 'nav-link',
        id : 'home'
    },
    {
        itemName: 'Services',
        url: 'services',
        cName: 'nav-link',
        id: 'services'
    },
    {
        itemName: 'Our Exerptise',
        url: 'our-exerptise',
        cName: 'nav-link',
        id: 'expertises'
    },
    {
        itemName: 'Who we are',
        url: 'who-we-are',
        cName: 'nav-link',
        id: 'who-we-are'
    },
    {
        itemName: 'Contact us',
        url: 'contact-us',
        cName: 'nav-link',
        id: 'contact-us'
    }
]