import React from 'react';

function Expertises() {
    return (
        <div className="our-expertise p-4" id="expertises" style={{background :'#fff', minHeight : '750px'}}>
            <div className="container text-center">
                <h2 className="fs-2 text-uppercase lh-base" style={{ color: '#ED7117' }}>Our Expertise</h2>
                <p className="fs-5 fw-normal lh-base" style={{color : '#9C9C9C'}}>
                    Simba solution is dedicated to IT solution for logistics domain with
                    a special focus into the african market.
                </p>
                <div className="boxes px-5" id="boxes">
                    <div className="row">
                        <div className="col-md-6 my-3 col-sm-12">
                            <div className="box" data-aos="fade-right">
                                <img src="img/onlogis.png" className="img-fluid" style={{ width: '40%', marginBottom: '2.5rem' }} alt="Onlogis Logo" />
                                <h4 className="">Onlogis</h4>
                                <p className="mb-0" style={{ paddingBottom: '38px' }}>
                                    All in one cloud centralized ERP solution to improve logistics and freight
                                    forwarding companies.
                                </p>
                                <a href="http://www.onlogis.com" target="_blank" rel="noreferrer">Visit website</a>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 col-sm-12">
                            <div className="box" data-aos="fade-left">
                                <img src="img/simba-express.png" className="img-fluid mb-4" alt="Simba Express Logo" />
                                <h4>Simba Express</h4>
                                <p  className="mb-0" style={{ paddingBottom: '60px' }}>
                                    Simba Express your Hometown and national express deliveries
                                </p>
                                <a href="http://simba-express.com" target="_blank" rel="noreferrer">Visit website</a>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 col-sm-12">
                            <div className="box" data-aos="fade-right">
                                <img src="img/simba_tracking.png" className="img-fluid mb-4" alt="Simba Tracking Logo" />
                                <h4>Simba Tracking</h4>
                                <p>
                                Simba Tracking software is destinated for the management of fleet, Cargo, and Drivers all in 
                                one single platform. The System dashboard provides you with a Real-time visibility and control of all your Route Transportation and Logistics Tracking in One Screen.
                                </p>
                                <a href="https://simba-tracking.com/" target="_blank">Visit website</a>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 col-sm-12">
                            <div className="box" data-aos="fade-left">
                                <img src="img/simba-restaurant.png" className="img-fluid mb-4" alt="" />
                                <h4>Simba Restaurant</h4>
                                <p>
                                    We simplify the food delivery concept and provide you with a compete customized solution
                                    for
                                    your business sucessful operations.
                                </p>
                                <a href="/simba-restaurant" target="_blank">Visit website</a>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 col-sm-12">
                            <div className="box" data-aos="fade-right">
                                <img src="img/simba-taxi.png" className="img-fluid mb-4" alt="Simba Taxi Logo" />
                                <h4>Simba Taxi</h4>
                                <p>
                                    A taxi uber type solution, we provide you with the complete personalized solution to run
                                    succesfully your own business
                                </p>
                                <a href="/simba-taxi" target="_blank">Visit website</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expertises;
