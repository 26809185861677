import React from 'react';

function SimbaRestaurant() {
    return (
        <div className="container text-center mt-5" style={{ background: '#FFFBF3' }}>
            <img src="/img/simba-restaurant.png" style={{ minWidth: '200px', height: 'auto', marginTop: '100px', marginBottom: '20px' }} alt="" srcset="" />
            <p className="fw-bold lh-base fs-5">
                The website is under construction...
            </p>
        </div>
    )
}

export default SimbaRestaurant;
