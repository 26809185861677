import React from 'react';

function Hero() {
    return (
        <div className="my-5" id="home">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6 me-auto mt-sm-5 ">
                        <p className="fs-5 fw-light lh-base pt-sm-0 pt-3 text-center text-sm-start">
                            Simba solution is UK based company dedicated to digital solutions
                            for the logistic and freight forwarding industries.
                        </p>
                    </div>
                    <div className="col-md-5 mt-5">
                        <img src="img/Group.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;
