import React from 'react';
import Experience from '../components/Experience';
import Expertises from '../components/Expertises';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar/Navbar';
import Services from '../components/Services';
import WhoWeAre from '../components/WhoWeAre';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer/Footer';

function Home() {
    return (
        <>
            <Navbar />
            <Hero />
            <Services />
            <Expertises />
            <Experience />
            <WhoWeAre />
            <ContactUs />
            <Footer />
        </>
    )
}

export default Home;
