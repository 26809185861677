import React from 'react';

function WhoWeAre() {
    return (
        <div className="who-we-are p-5" id="who-we-are" style={{ background: '#fff', minHeight: '300px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src="img/who-we-are.png" className="img-fluid" data-aos="fade-right" alt="" />
                    </div>
                    <div className="col-md-8 mt-3 text-center text-sm-start" data-aos="fade-left">
                        <h2 className="fs-3 lh-base fw-normal mt-sm-0 mt-2" style={{color : '#ED7117'}}>Who We Are</h2>
                        <p className="fs-6 lh-base " style={{color: '#242424'}}>
                            We are a dedicated acheivements oriented team with 20 years plus of successful operations in
                            both
                            logistics and Information Technology industries.
                            We combine our expertise with the essentail ressources to provide our clients with efficient
                            and
                            clear solutions.
                        </p>
                        <p className="fs-6 lh-base" style={{color: '#242424'}}>
                            We operate in several offices through different countinents Europe, Africa, and Asia to
                            guarrantee the proximity to our customers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre;
