import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactUs() {
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_kz3impk', 'template_t4rs346', e.target, 'user_0Kc222Ur2ZzXo7ahMawuc')
            .then((result) => {
                console.log(result.text);
                setMessage('Thank you. Your message is sent successfuly');
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <div className="contact-us" style={{ background: '#f7f7f7' }} id="contact-us">
            <div className="container my-container">
                <div className="row">
                    <div className="col-md-8 mx-auto my-5">
                        <h3 className="fs-2 lh-base text-center " style={{ color: '#ED7117' }}>Contact Us</h3>
                        <p className="lead lh-base fs-6 mt-1 text-center " style={{ color: '#888' }}>Give us a call or send us an email and we will get back to you as soon possible</p>
                        <form onSubmit={sendEmail}>
                            {message && (<div className="alert alert-success">{message}</div>)}
                            <div className="form-group mb-2 ">
                                <input type="text" name="name" placeholder="Full Name" className="form-control" required />
                            </div>
                            <div className="form-group mb-2 ">
                                <input type="email" name="email" placeholder="Email" className="form-control" required />
                            </div>
                            <div className="form-group mb-2 ">
                                <input type="text" name="phone" placeholder="Phone" className="form-control" required />
                            </div>
                            <div className="form-group mb-2 ">
                                <input type="text" name="subject" placeholder="Subject" className="form-control" required />
                            </div>
                            <div className="form-group mb-2 ">
                                <textarea name="message" id="" cols="30" rows="10" placeholder="Message" className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '8px 30px' }}>Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
