import React from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

function Footer() {

    return (
        <>
            <div className="footer p-5" style={{color:'#fff', background: "linear-gradient(rgba(38, 50, 56, 0.9), rgba(0, 0, 0, .7)),url('/img/Frame 1.png')" }}>
                <div className="container">
                    <div className="scroll-top float-right text-center">
                        <i className="fas fa-chevron-up " style={{ color: '#ED7117' }} onClick={() => scroll.scrollToTop()}></i>
                    </div>
                    <div className="boxes">
                        <div className="box">
                            <img src="img/s.png" alt="" />
                        </div>
                        <div className="box">
                            <ul className="ps-0">
                                <li>Simba Solution Limited,</li>
                                <li>71-75 Shelton Street</li>
                                <li>Covet Garden,</li>
                                <li>London</li>
                                <li>WC2H 9JQ</li>
                                <li>UTR: 43133 27262</li>
                            </ul>
                        </div>
                        <div className="box">
                            <h4>Our Services</h4>
                            <ul className="ps-0">
                                <li>Web Development</li>
                                <li>Mobile Development</li>
                                <li>DevOps</li>
                                <li>Logistic Consultation</li>
                            </ul>
                        </div>
                        <div className="box">
                            <h4>Quick Links</h4>
                            <ul className="ps-0">
                                <li>Franchise in your country</li>
                                <li>Become our partner</li>
                                <li>Enterprise Signin</li>
                                <li>API</li>
                            </ul>
                        </div>
                        <div className="box">
                            <h4>Contact Us</h4>
                            <ul className="ps-0">
                                <li><i className="fas fa-envelope"></i> <span>info@simba-solution.com</span></li>
                                <li><i className="fas fa-phone-alt"></i>+ <span>442037692827</span></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className="copy">
                        <p className="copyrights">
                            &copy; <span>{new Date().getFullYear()}</span> Simba Solution
                        </p>
                        <div className="social-icons">
                            <Link to=""><i className="fab fa-instagram"></i></Link>
                            <Link to=""><i className="fab fa-facebook"></i></Link>
                            <Link to=""><i className="fab fa-twitter"></i></Link>
                            <Link to=""><i className="fab fa-youtube"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;
