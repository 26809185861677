import React from 'react';

function Services() {
    return (
        <div className="services pt-5" style={{ minHeight: '600px', background: '#FAFAFA' }} id="services">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <h2 className="fs-2 text-uppercase lh-base" style={{ color: '#ED7117' }}>Our services</h2>
                        <p className="fs-5 fw-normal lh-base" style={{color : '#9C9C9C'}}>
                            We offer to our customers different digital solutions within logistics
                            and freight forwarding industries
                        </p>
                    </div>
                </div>
                <div className="row boxes align-items-stretch mt-3">
                    <div className="col col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="box h-100 w-100" data-aos="flip-left">
                            <img src="img/wd.png" alt="" />
                            <h3>Web Development</h3>
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="box h-100 w-100" data-aos="flip-left">
                            <img src="img/md.png" alt="" />
                            <h3>Mobile Development</h3>
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-6 col-lg-3 mb-4">
                        <div className="box h-100 w-100" data-aos="flip-left">
                            <img src="img/dev-ops.png" alt="" />
                            <h3>Devops</h3>
                        </div>
                    </div>
                    <div className="col col-sm-12 col-md-6 col-lg-3 mb-10 mb-4">
                        <div className="box h-100 w-100" data-aos="flip-left">
                            <img src="img/logistic.png" alt="" />
                            <h3>Logistic consultation</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;
