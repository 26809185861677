import React from 'react';

function Experience() {
    return (
        <div className="exp p-5" style={{background : '#FFFBF3', minHeight : '300px'}}>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-3">
                        <img src="img/10y-exp.png" alt="" />
                        <h3 data-aos="fade-right" className="fs-5 text-uppercase lh-sm" style={{ marginTop: '35px' }}>Years of <br /> experience</h3>
                    </div>
                    <div className="col-md-3">
                        <img src="img/Group21.png" alt="" />
                        <h3 data-aos="fade-right" className="fs-5 text-uppercase lh-sm">Full-Time <br /> Engineers</h3>
                    </div>
                    <div className="col-md-3">
                        <img src="img/clients-world-wide.png" alt="" />
                        <h3 data-aos="fade-right" className="fs-5 text-uppercase lh-sm">clients <br /> world wide</h3>
                    </div>
                    <div className="col-md-3">
                        <img src="img/completed-projects.png" alt="" />
                        <h3 data-aos="fade-right" className="fs-5 text-uppercase lh-sm">Completed <br /> Projects</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience;
