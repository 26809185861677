import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import SimbaRestaurant from './pages/SimbaRestaurant';
import SimbaTaxi from './pages/SimbaTaxi';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/simba-restaurant' component={SimbaRestaurant} />
        <Route path='/simba-taxi' component={SimbaTaxi} />
        <Route path='/' component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
