import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom';
import { MenuItems } from './MenuItems';
import { Link } from "react-scroll";

class Navbar extends Component {

    state = {
        clicked: false,
        closeMenu: false
    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg sticky-top top-0">
                    <div className="container">
                        <Link className="navbar-brand" to="/" style={{ cursor: 'pointer' }}>
                            <img src="img/logo.png" className="logo-img " alt="Simba Solution Logo" />
                        </Link>
                        <div className="menu-icon" onClick={this.handleClick}>
                            <i className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                        </div>
                        <div>
                            <ul className={this.state.clicked ? 'navbar-nav active' : 'navbar-nav'}>
                                {MenuItems.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={item.id} spy={true} smooth={true} offset={-70} duration={200} className={this.state.clicked ? `${item.cName} active` : `${item.cName}`} onClick={this.state.closeMenu} style={{ cursor: 'pointer' }}>
                                                {item.itemName}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

export default Navbar;
